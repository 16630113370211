import styled from "styled-components"

const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.text.white};
  font-weight: 700;
  transition: filter 0.2s ease-in-out;
  justify-self: start;

  &:hover {
    filter: brightness(85%);
  }
`

export default Link
