import styled from "styled-components"

const Wrapper = styled.div`
  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 32px auto 0;
    max-width: 1200px;
  }
`

export default Wrapper
