import styled from "styled-components"

const Price = styled.div`
  margin-top: -10px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    margin: 0;
    justify-self: start;
  }
`

export default Price
