import styled from "styled-components"

const Wrapper = styled.div`
  margin: 30px auto;
  max-width: 1200px;

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
  }
`

export default Wrapper
