import styled from "styled-components"

const Telephone = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.primary.green};
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
`

export default Telephone
