import styled from "styled-components"

const Wrapper = styled.div`
  display: block;
  position: relative;
  width: 539px;
  height: 544px;
  margin: 0 0 0 auto;
  z-index: 2;
`

export default Wrapper
