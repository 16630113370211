import React from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"

//Style components imports
import Wrapper from "./Wrapper"
import Title from "./Title"
import Subtitle from "./Subtitle"
import Text from "./Text"
import Image from "./Image"
import Line from "./Line"
import GreenText from "../../../shared-styled-components/GreenText"
import Button from "../../../shared-styled-components/Button"
import Border from "../../../shared-styled-components/Border"
import ImageWrapper from "../../../shared-styled-components/ImageWrapper"
import StyledMedia from "./StyledMedia"

const Rooms = props => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "rooms" }
          name: { in: ["dvojlozko", "apartman"] }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Line desktop />
      <div>
        <Title>
          Apartmán s manželskou <br />
          posteľou
        </Title>
        <Subtitle>
          Apartmán s prísteľkou <GreenText>75.00 € </GreenText> izba / noc.
        </Subtitle>
        <Text>
          Je to apartmán s prístelkou, ktorý má aj menšiu obývaciu miestnosť,
          kde je rozkladací gauč. V izbe sa nachádza aj televízia a Wi-Fi. Pri
          rezervácii je možné si pridať do kolónky "odkaz pre nás" aj či máte
          záujem o polpenziu alebo raňajky.
        </Text>
        <StyledMedia greaterThanOrEqual="tablet">
          <ImageWrapper margin="32px auto" width="453px">
            <Border width="453px" height="273px" />
            <Image fluid={data.allFile.edges[0].node.childImageSharp.fluid} />
          </ImageWrapper>
        </StyledMedia>
        <StyledMedia lessThan="tablet">
          <ImageWrapper margin="32px auto" left="-25px" width="100vw">
            <Image fluid={data.allFile.edges[0].node.childImageSharp.fluid} />
          </ImageWrapper>
        </StyledMedia>
        <br />
        <Button onClick={() => navigate("/prihlasenie")}>Rezervovať</Button>
      </div>
      <Line />
      <div>
        <Title>Izba s dvojlôžkom</Title>
        <Subtitle>
          Dvojlôžková izba so sprchou <GreenText>55.00 € </GreenText> izba /
          noc.
        </Subtitle>
        <Text>
          Je to dvojlôžková izba so sprchou a WC, v izbe sa nachádza aj
          televízia a Wi-Fi.
        </Text>
        <StyledMedia greaterThanOrEqual="tablet">
          <ImageWrapper margin="32px auto" width="453px">
            <Border width="453px" height="273px" />
            <Image fluid={data.allFile.edges[1].node.childImageSharp.fluid} />
          </ImageWrapper>
        </StyledMedia>
        <StyledMedia lessThan="tablet">
          <ImageWrapper margin="32px auto" left="-25px" width="100vw">
            <Image fluid={data.allFile.edges[1].node.childImageSharp.fluid} />
          </ImageWrapper>
        </StyledMedia>
        <br />
        <Button onClick={() => navigate("/prihlasenie")}>Rezervovať</Button>
      </div>
    </Wrapper>
  )
}

export default Rooms
