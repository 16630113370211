import React from "react"
import { Media } from "../../utils/Media"
import { graphql, useStaticQuery } from "gatsby"

//React components imports
import SectionTitle from "../SectionTitle"
import DesktopCanteenAndCafe from "./DesktopCanteenAndCafe"
import MobileCanteenAndCafe from "./MobileCanteenAndCafe"

//Style components imports
import Wrapper from "./Wrapper"
import Text from "./Text"
import ScrollTo from "../../shared-styled-components/ScrollTo"

const FoodSecion = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { name: { in: ["food-1", "food-2", "food-3", "food-4"] } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <ScrollTo id="jedalen" />
      <SectionTitle>Kaviareň a jedáleň</SectionTitle>
      <Text>
        Môžete si u nás vychutnať chuť dobrej kávy v príjemnom prostredí ako sa
        aj posilniť chutným domácim jedlom a zaspomínať na mamičkinu kuchyňu.
      </Text>

      <Media lessThan="largeDesktop">
        <MobileCanteenAndCafe images={data.allFile.edges} />
      </Media>
      <Media greaterThanOrEqual="largeDesktop">
        <DesktopCanteenAndCafe images={data.allFile.edges} />
      </Media>
    </Wrapper>
  )
}

export default FoodSecion
