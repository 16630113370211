import styled from "styled-components"

const Wrapper = styled.div`
  display: none;
  height: 50px;
  width: 50px;
  padding-bottom: 50px;
  background-color: #676767;
  border-radius: 50%;
  position: sticky;
  bottom: 50px;
  margin: 0 -50px -20px auto;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background: #7a7a7a;
  }

  @media (${props => props.theme.mediaQueries.desktop}) {
    display: block;
  }
`

export default Wrapper
