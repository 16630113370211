import React from "react"
import { graphql, useStaticQuery } from "gatsby"

//React components imports
import IntroImageDesktop from "./IntroImageDesktop"
import IntroImageMobile from "./IntroImageMobile"

//Styled components imports
import Background from "./Background"
import Title from "./Title"
import Wrapper from "./Wrapper"
import Greating from "./Greating"
import Text from "./Text"
import Link from "./Link"
import StyledMedia from "./StyledMedia"

const Introduction = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "introduction" } }) {
        edges {
          node {
            name
            relativeDirectory
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <Wrapper>
        <Title>
          Hotel <span style={{ fontWeight: `200` }}>Dolina</span>
        </Title>
        <StyledMedia lessThan="largeDesktop">
          <IntroImageMobile image={data.allFile.edges[0]} />
        </StyledMedia>
        <StyledMedia greaterThanOrEqual="largeDesktop">
          <IntroImageDesktop images={data.allFile.edges} />
        </StyledMedia>
      </Wrapper>

      <Background>
        <div style={{ maxWidth: `1200px`, margin: "auto" }}>
          <Greating>Vitajte!</Greating>
          <Text>
            Hotel s rodinnou atmosférou sa nachádza priamo v centre mesta Veľký
            Krtíš v regióne Novohrad. Svojou vybavenosťou a kvalitnými službami
            plne zodpovedá európskemu štandardu v danej hotelovej kategórii.
            <br />
            <br />
            História hotela sa začala písať v druhom polroku 1958, kedy sa
            začala jeho výstavba. V roku 1965 bol už v plnej prevádzke a
            pomenovali ho Hotel DOLINA podľa Bani Dolina, nakoľko Veľký Krtíš
            bol vybudovaný ako banícke mesto.
            <br />
            <br />
            V súčasnosti Hotel ponúka, okrem reštauračných a ubytovacích služieb
            aj priestory a služby pre firemné akcie, svadby a promócie. V okolí
            mesta môžu naši zákazníci stráviť príjemné chvíle kultúrnym,
            turistickým aj športovým vyžitím.
            <br />
            <br />
            Pozrite sa na naše <Link to="/vylety">tipy kam na výlet</Link>.
          </Text>
        </div>
      </Background>
    </>
  )
}

export default Introduction
