import React from "react"

//Styled components imports
import Wrapper from "./Wrapper"
import Image from "./Image"

const IntroImageMobile = props => {
  return (
    <Wrapper>
      <Image fluid={props.image.node.childImageSharp.fluid} />
    </Wrapper>
  )
}

export default IntroImageMobile
