import styled from "styled-components"

const Text = styled.p`
  text-align: center;
  max-width: 700px;
  margin: 24px auto 0;
  position: relative;
  z-index: 2;

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    text-align: left;
    margin: 55px 0 0;
    width: 63%;
    max-width: 780px;
  }
`

export default Text
