import styled from "styled-components"

const Container = styled.div`
  display: grid;
  justify-content: center;

  @media (${props => props.theme.mediaQueries.desktop}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
    max-width: 980px;
    margin: 0 auto;
  }
`

export default Container
