import styled from "styled-components"

const Border = styled.div`
  border: ${props => props.border || "5px"} solid
    ${props => props.theme.colors.imageBorder.grey};
  border-radius: ${props => props.radius};
  width: ${props => props.width || "300px"};
  height: ${props => props.height || "300px"};
  position: absolute;
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  right: ${props => props.right};
`

export default Border
