import styled from "styled-components"
import Img from "gatsby-image"

const Image = styled(Img)`
  width: 100vw;
  height: 235px;
  object-fit: cover;
  z-index: -1;

  @media (${props => props.theme.mediaQueries.tablet}) {
    height: 273px;
    width: 100%;
  }
`

export default Image
