import styled from "styled-components"

const Title = styled.h1`
  color: ${props => props.theme.colors.text.darkGrey};
  text-align: center;
  font-size: 36px;
  font-family: "Raleway", sans-serif;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 42px;
  }
`

export default Title
