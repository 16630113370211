import React from "react"

//React components imports
import SectionTitle from "../SectionTitle"
import GoogleMap from "../GoogleMap"

//Style components imports
import Wrapper from "./Wrapper"
import Container from "./Container"
import ContactType from "./ContactType"
import Contacts from "./Contacts"
import MapWrapper from "./MapWrapper"
import Link from "./Link"
import Border from "../../shared-styled-components/Border"
import AddressText from "./AddressText"
import ScrollTo from "../../shared-styled-components/ScrollTo"

const ContactSection = () => {
  return (
    <Wrapper>
      <ScrollTo id="kontakt" />
      <SectionTitle>Kontakt</SectionTitle>
      <Container>
        <div>
          <AddressText title>Hotel Dolina</AddressText>
          <div>
            <AddressText>Námestie Augusta Horislava Škultétyho 2</AddressText>
            <AddressText>Veľký Krtíš 99001</AddressText>
            <AddressText> Slovensko</AddressText>
          </div>
          <Contacts>
            <>
              <ContactType>Telefón</ContactType>
              <Link href="tel:+421903530843">+421 903 530 843</Link>
            </>
            {/* <>
              <ContactType>Mobil</ContactType>
              <Link href="tel:+421 903 530 843">+421 903 530 843</Link>
            </> */}
            <>
              <ContactType>E-Mail</ContactType>
              <Link
                href="mailto:hoteldolinavk@gmail.com"
                style={{ wordBreak: "break-word" }}
              >
                hoteldolinavk@gmail.com
              </Link>
            </>
          </Contacts>
        </div>
        <MapWrapper>
          <Border width="100%" height="100%" />
          <GoogleMap
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBMxcwsz9DAbe3_03iJo8DGY9YYEUCUUbc&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div style={{ height: `100%`, padding: "5px 5px" }} />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </MapWrapper>
      </Container>
    </Wrapper>
  )
}

export default ContactSection
