import styled from "styled-components"

const Title = styled.h2`
  font-size: 24px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 36px;
  }
`

export default Title
