import styled from "styled-components"

const Container = styled.div`
  display: grid;
  grid-template-columns: 74px 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 8px 32px;
  max-width: 225px;
  margin: 16px auto 32px;
  text-align: left;
  height: 85px;
`

export default Container
