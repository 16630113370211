import React from "react"

//React components imports
import SectionTitle from "../SectionTitle"
import Rooms from "./Rooms"

//Style components imports
import Wrapper from "./Wrapper"
import ScrollTo from "../../shared-styled-components/ScrollTo"
import Text from "./Text"
import Link from "./Link"
import Telephone from "./Telephone"

const AccommodationSecion = () => {
  return (
    <Wrapper>
      <ScrollTo id="ubytovanie" />
      <SectionTitle>Ubytovanie</SectionTitle>
      <Text>
        Ponúkame vám ubytovanie prevažne v dvojlôžkových izbách, ale k 
        dispozícii sú aj apartmány.
      </Text>
      <Text>
        Pre ubytovaných je k dispozícii aj spoločná kuchynka a mini fitnes.
      </Text>
      <Rooms />
      <Text>
        Prípade rezervácie izby je možné využiť náš{" "}
        <Link to="/prihlasenie">rezervačný systém</Link> alebo priamo{" "}
        <Telephone href="tel:+421903530843">zavolať</Telephone> na recepciu pri
        vytvorení alebo zrušení pobytu. <br />
        Ďakujeme.
      </Text>
    </Wrapper>
  )
}

export default AccommodationSecion
