import React, { useContext, useEffect, useCallback, useState } from "react"
import Context from "../store/Context"

//React components imports
import Layout from "../components/Layout"
import Introduction from "../components/Introduction"
import AccommodationSection from "../components/AccommodationSection"
import FoodSection from "../components/FoodSection"
import PricingSection from "../components/PricingSection"
import ContactSection from "../components/ContactSection"
import ScrollUpArrow from "../components/ScrollUpArrow"
import Seo from "../components/Seo"

const HomePage = props => {
  const { dispatch } = useContext(Context)
  const [showScrollUp, setShowScrollUp] = useState(false)

  useEffect(() => {
    dispatch({
      type: "SET_PATH",
      path: props.location.pathname + props.location.hash,
    })
  }, [dispatch, props.location])

  const handleScroll = useCallback(() => {
    if (window.scrollY > 1100) {
      setShowScrollUp(true)
    } else {
      setShowScrollUp(false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, false)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Layout>
      <Seo />
      <Introduction />
      <AccommodationSection />
      <FoodSection />
      <PricingSection />
      <ContactSection />
      {showScrollUp && <ScrollUpArrow show={showScrollUp} />}
    </Layout>
  )
}

export default HomePage
