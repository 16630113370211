import styled from "styled-components"

const Title = styled.h2`
  margin: 0 5px;
  font-size: 28px;
  height: 84px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 36px;
  }
`

export default Title
