import styled from "styled-components"

const Text = styled.p`
  max-width: 450px;
  margin: auto;

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    height: 92px;
  }
`

export default Text
