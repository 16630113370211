import React from "react"
import { Media } from "../../../utils/Media"

//Style components imports
import Wrapper from "./Wrapper"
import Title from "../DesktopCanteenAndCafe/Title"
import Text from "../DesktopCanteenAndCafe/Text"
import Container from "../DesktopCanteenAndCafe/Container"
import Image from "./Image"
import Line from "./Line"
import Link from "../DesktopCanteenAndCafe/Link"
import GreenText from "../../../shared-styled-components/GreenText"
import ImageWrapper from "../../../shared-styled-components/ImageWrapper"
import Border from "../../../shared-styled-components/Border"

const CanteenAndCafe = props => {
  return (
    <Wrapper>
      <Line />
      <div>
        <Title>Kaviareň</Title>
        <Text>
          Príďte si posedieť pri dobrej kávičke alebo čaji. Máme množstvo
          zaujímavých ponúk.
        </Text>
        <>
          <Text green>OTVÁRACIE HODINY</Text>
          <Container>
            <div>PO - ŠT</div>
            <div></div>
            <div>PI - SO</div>
            <div></div>
            <div>NE</div>
            <div></div>
          </Container>
        </>
        <>
          <Media greaterThanOrEqual="tablet">
            <ImageWrapper margin="auto" width="453px">
              <Border width="453px" height="273px" />
              <Image fluid={props.images[1].node.childImageSharp.fluid} />
            </ImageWrapper>
          </Media>
          <Media lessThan="tablet">
            <ImageWrapper width="100vw" left="-25px">
              <Image fluid={props.images[1].node.childImageSharp.fluid} />
            </ImageWrapper>
          </Media>
        </>
      </div>
      <Line />
      <div>
        <Title>Jedáleň</Title>
        <Text>
          Každý deň podávame čerstvé a chutné obedy za{" "}
          <GreenText bold>rozumnú cenu</GreenText> .
        </Text>
        <Link href="https://dolina.vkinfo.sk/" target="_blank">
          
        </Link>
        <>
          <Text green>OTVÁRACIE HODINY</Text>
          <Container>
            <div>PO - PI</div>
            <div></div>
            <div>SO - NE</div>
            <div></div>
          </Container>
        </>
        <>
          <Media greaterThanOrEqual="tablet">
            <ImageWrapper margin="auto" width="453px">
              <Border width="453px" height="273px" />
              <Image fluid={props.images[2].node.childImageSharp.fluid} />
            </ImageWrapper>
          </Media>
          <Media lessThan="tablet">
            <ImageWrapper width="100vw" left="-25px">
              <Image fluid={props.images[2].node.childImageSharp.fluid} />
            </ImageWrapper>
          </Media>
        </>
      </div>
    </Wrapper>
  )
}

export default CanteenAndCafe
