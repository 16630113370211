import styled from "styled-components"
import { ArrowIosUpward } from "@styled-icons/evaicons-solid/ArrowIosUpward"

const Arrow = styled(ArrowIosUpward)`
  color: ${props => props.theme.colors.text.white};
  cursor: pointer;
  pointer-events: none;
`

export default Arrow
