import styled from "styled-components"

const Link = styled.a`
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.colors.primary.green};
  color: ${props => props.theme.colors.primary.green};
  font-weight: 700;
  margin-top: 12px;
`

export default Link
