import styled from "styled-components"

const ImageWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => props.width || "300px"};
  margin: ${props => props.margin};
  margin-top: ${props => props.top};
  margin-bottom: ${props => props.bottom};
  margin-left: ${props => props.left};
  margin-right: ${props => props.right};
`

export default ImageWrapper
