import React from "react"

//Styled components imports
import Wrapper from "./Wrapper"
import Image from "./Image"
import Border from "./Border"

const IntroImageDesktop = props => {
  return (
    <Wrapper>
      <Image
        right="0"
        bottom="0"
        fluid={props.images[2].node.childImageSharp.fluid}
        style={{ position: "absolute" }}
      />
      <Border right="0" bottom="0" />
      <Image
        fluid={props.images[1].node.childImageSharp.fluid}
        right="13px"
        style={{ position: "absolute" }}
      />
      <Border right="13px" />
      <Image
        fluid={props.images[0].node.childImageSharp.fluid}
        top="89px"
        style={{ position: "absolute" }}
      />
      <Border top="89px" />
    </Wrapper>
  )
}

export default IntroImageDesktop
