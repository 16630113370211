import styled from "styled-components"

const Container = styled.div`
  display: grid;
  color: ${props => props.theme.colors.text.darkGrey};
  margin: 42px auto 0;
  justify-items: center;
  grid-gap: 18px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px 68px;
    max-width: 1200px;
  }
`

export default Container
