import styled from "styled-components"

const Line = styled.div`
  height: 1px;
  width: 30px;
  background: ${props => props.theme.colors.primary.green};
  margin-left: ${props => props.left};
  margin-right: ${props => props.right};
`

export default Line
