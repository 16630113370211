import styled from "styled-components"

const ScrollTo = styled.div`
  position: absolute;
  top: -80px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    top: -40px;
  }

  ${props => props.id === "cennik" && "top: -40px;"}
`

export default ScrollTo
