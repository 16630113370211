import styled, { css } from "styled-components"

const MobileBackground = css`
  margin-left: -25px;
  margin-top: -5px;
  padding: 18px 30px 24px;
  z-index: 1;

  &:before {
    border-bottom: 42px solid ${props => props.theme.colors.primary.green};
    border-right: 100vw solid transparent;
    content: "";
    position: absolute;
    top: -41px;
    left: 0;
    width: 0;
  }
`

const DesktopBackground = css`
  margin-top: -300px;
  padding: 90px 120px 45px;

  &:before {
    border-bottom: 400px solid transparent;
    border-right: 60vw solid ${props => props.theme.colors.primary.darkGrey};
    content: "";
    position: absolute;
    top: 53px;
    left: 0;
    width: 100%;
  }

  &:after {
    border-top: 55px solid ${props => props.theme.colors.primary.darkGrey};
    border-left: 40vw solid transparent;
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
  }
`

const Background = styled.div`
  background: ${props => props.theme.colors.primary.green};
  color: ${props => props.theme.colors.text.darkGrey};
  display: block;
  height: 100%;
  width: 100vw;
  position: relative;

  ${() => MobileBackground}

  @media (${props => props.theme.mediaQueries.tablet}) {
    margin-left: -120px;
  }

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    ${() => DesktopBackground}
  }
`

export default Background
