import styled from "styled-components"

const Wrapper = styled.div`
  width: 100vw;
  margin-left: -25px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    margin-left: -120px;
  }
`

export default Wrapper
