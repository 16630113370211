import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

//Styled components imports
import Wrapper from "./Wrapper"
import Arrow from "./Arrow"

const ScrollUpArrow = props => {
  const handleClick = e => {
    scrollTo(e.target.id)
  }
  return (
    <Wrapper onClick={handleClick} id="header">
      <Arrow />
    </Wrapper>
  )
}

export default ScrollUpArrow
