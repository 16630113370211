import styled from "styled-components"

const Wrapper = styled.div`
  background: ${props => props.theme.colors.primary.green};
  width: 100vw;
  padding-top: 32px;
  padding-bottom: 42px;
  margin-left: -25px;
  position: relative;

  @media (${props => props.theme.mediaQueries.tablet}) {
    margin-left: -120px;
  }
`

export default Wrapper
