import styled from "styled-components"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  margin: 30px auto;
  max-width: 1200px;
`

export default Wrapper
