import React from "react"

//React components imports

//Style components imports
import Wrapper from "./Wrapper"
import Title from "./Title"
import Container from "./Container"
import Item from "./Item"
import Price from "./Price"
import Line from "./Line"
import ScrollTo from "../../shared-styled-components/ScrollTo"

const PricingSection = () => {
  return (
    <Wrapper>
      <ScrollTo id="cennik" />

      <Title>Cenník</Title>
      <Container>
        <>
          <Item>Denné menu</Item>
          <Price>rozumnú cenu</Price>
        </>
        <>
          <Item>CENY PLATNÉ OD 01.01 2023</Item>
          <Price></Price>
        </>
        <>
          <Line width="360px" right />
          <Line width="155px" />
        </>
        <>
          <Item>Dvojlôžková izba so sprchou</Item>
          <Price>55.00 € izba / noc</Price>
        </>
        <>
          <Item>Apartmán s prístelkou</Item>
          <Price>75.00 € izba / noc</Price>
        </>
        <>
          <Item>Daň mestu za ubytovanie</Item>
          <Price>1.00 € osoba / noc</Price>
        </>
      </Container>
    </Wrapper>
  )
}

export default PricingSection
