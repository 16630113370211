import styled from "styled-components"
import Img from "gatsby-image"

const Image = styled(Img)`
  display: inline-block;
  width: 270px;
  height: 168px;
  object-fit: cover;
  z-index: -1;
`

export default Image
