import styled from "styled-components"

const Title = styled.h1`
  font-size: 28px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 42px;
  }
`

export default Title
