import React from "react"

//Style components imports
import Wrapper from "./Wrapper"
import Title from "./Title"
import Text from "./Text"
import Container from "./Container"
import Image from "./Image"
import Line from "./Line"
import Link from "./Link"
import GreenText from "../../../shared-styled-components/GreenText"
import Border from "../../../shared-styled-components/Border"
import ImageWrapper from "../../../shared-styled-components/ImageWrapper"

const CanteenAndCafe = props => {
  return (
    <Wrapper>
      <div>
        <Title>Kaviareň</Title>
        <Text>
          Príďte si posedieť pri dobrej kávičke alebo čaji.
          <br /> Máme množstvo zaujímavých ponúk.
        </Text>
        <>
          <Text green>OTVÁRACIE HODINY</Text>
          <Container>
            <div>PO - ŠT</div>
            <div></div>
            <div>PI - SO</div>
            <div></div>
            <div>NE</div>
            <div></div>
          </Container>
        </>
        <>
          <ImageWrapper margin="0 15px" width="270px">
            <Border width="270px" height="168px" />
            <Image fluid={props.images[0].node.childImageSharp.fluid} />
          </ImageWrapper>
          <ImageWrapper width="270px">
            <Border width="270px" height="168px" />
            <Image fluid={props.images[1].node.childImageSharp.fluid} />
          </ImageWrapper>
        </>
      </div>
      <Line />
      <div>
        <Title>Jedáleň</Title>
        <Text>
          Každý deň podávame čerstvé a chutné obedy za{" "}
          <GreenText bold>rozumnú cenu</GreenText> .
        </Text>
        <Link href="https://dolina.vkinfo.sk/" target="_blank">
          
        </Link>
        <>
          <Text green top="18px">
            OTVÁRACIE HODINY
          </Text>
          <Container>
            <div>PO - PI</div>
            <div></div>
            <div>SO - NE</div>
            <div></div>
          </Container>
        </>
        <>
          <ImageWrapper margin="0 15px" width="270px">
            <Border width="270px" height="168px" />
            <Image fluid={props.images[2].node.childImageSharp.fluid} />
          </ImageWrapper>
          <ImageWrapper width="270px">
            <Border width="270px" height="168px" />
            <Image fluid={props.images[3].node.childImageSharp.fluid} />
          </ImageWrapper>
        </>
      </div>
    </Wrapper>
  )
}

export default CanteenAndCafe
