import styled from "styled-components"

const Text = styled.p`
  max-width: 500px;
  padding: 0 10px;
  margin: ${props => props.top || "30px"} auto 0;
  color: ${props => props.green && props.theme.colors.primary.green};
`

export default Text
