import styled from "styled-components"

const Greating = styled.h1`
  text-align: center;

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    text-align: left;
  }
`

export default Greating
