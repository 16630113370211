import styled from "styled-components"

const Contacts = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 12px 20px;
  margin: 24px 0;
`

export default Contacts
