import styled from "styled-components"
import Img from "gatsby-image"

const Image = styled(Img)`
  border-radius: 50%;
  width: 300px;
  height: 300px;
  object-fit: cover;
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  right: ${props => props.right};
  box-sizing: border-box;
  position: absolute;
`

export default Image
