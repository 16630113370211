import styled from "styled-components"

const ContactType = styled.div`
  text-transform: uppercase;
  color: ${props => props.theme.colors.primary.green};
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: normal;
`

export default ContactType
