import styled from "styled-components"

const Title = styled.h1`
  display: none;

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    display: block;
    font-size: 96px;
    font-weight: normal;
    width: 360px;
    text-transform: uppercase;
    margin-top: 21px;
  }
`

export default Title
