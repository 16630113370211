import styled from "styled-components"

const Border = styled.div`
  border: 10px solid ${props => props.theme.colors.imageBorder.grey};
  border-radius: 50%;
  width: 300px;
  height: 300px;
  position: absolute;
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  right: ${props => props.right};
`

export default Border
