import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

const Link = styled(GatsbyLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.primary.green};
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
`

export default Link
