import styled from "styled-components"

const Item = styled.div`
  color: ${props => props.theme.colors.text.darkGrey};
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  font-weight: normal;
  font-size: 16px;
  text-align: center;

  @media (${props => props.theme.mediaQueries.tablet}) {
    text-align: right;
    justify-self: right;
    font-size: 18px;
  }
`

export default Item
