import styled from "styled-components"

const MapWrapper = styled.div`
  height: 180px;
  max-width: 435px;
  width: 100%;
  position: relative;

  @media (${props => props.theme.mediaQueries.tablet}) {
    height: 240px;
  }
`

export default MapWrapper
