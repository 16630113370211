import styled, { css } from "styled-components"

const rightLine = css`
  display: block;
  width: 250px;
  justify-self: center;

  @media (${props => props.theme.mediaQueries.tablet}) {
    justify-self: end;
  }
`

const Line = styled.div`
  display: none;
  height: 1px;
  width: ${props => props.width};
  justify-self: start;
  background: ${props => props.theme.colors.text.darkGrey};
  ${props => props.right && rightLine}

  @media (${props => props.theme.mediaQueries.tablet}) {
    display: block;
  }
`

export default Line
