import React from "react"

//Style components imports
import Wrapper from "./Wrapper"
import Title from "./Title"
import Line from "./Line"

const SectionTitle = props => {
  return (
    <Wrapper>
      <Line right="12px" />
      <Title>{props.children}</Title>
      <Line left="12px" />
    </Wrapper>
  )
}

export default SectionTitle
